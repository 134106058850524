var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-up",class:{ mobile: _vm.isMobile }},[_c('div',{staticClass:"line-up__wrapper"},[_vm._m(0),_c('div',{staticClass:"line-up__content"},_vm._l((_vm.processedExpertData),function(LineUp,index){return _c('div',{key:index,staticClass:"line-up-card"},[_c('div',{staticClass:"line-up-row1"},[_c('div',{staticClass:"line-up-profile"},[_c('div',{staticClass:"line-up-profile-image-container",on:{"click":function($event){return _vm.navigateToExpert(LineUp.expert_id)}}},[_c('div',{staticClass:"line-up-triangle-background"}),_c('div',{class:['line-up-profile-image', { 'image-error': LineUp.imageError }]},[_c('img',{attrs:{"src":LineUp.profile_img3,"alt":"Profile Image"},on:{"error":function($event){return _vm.handleImageError(LineUp.expert_id)}}})]),(_vm.isBroadcastLive(LineUp))?_c('div',{staticClass:"live-badge-custom"},[_c('div',{staticClass:"live-custom"},[_c('v-icon',[_vm._v("mdi-circle")]),_c('span',[_vm._v("LIVE")])],1)]):_vm._e()])])]),_c('div',{staticClass:"line-up-row2"},[_c('div',{staticClass:"line-up-expert-des-container"},[_c('div',{staticClass:"line-up-expert-name"},[_c('span',[_vm._v(_vm._s(LineUp.expert_nickname))])]),_c('div',{staticClass:"line-up-expert-home-btn",on:{"click":function($event){return _vm.navigateToExpert(LineUp.expert_id)}}},[_c('img',{staticClass:"img",attrs:{"alt":`expert_home_svg`,"src":_vm.homeSvg}})])]),_c('div',{staticClass:"line-up-title"},[_c('p',[_vm._v(_vm._s(LineUp.title))])]),_c('div',{staticClass:"line-up-date",class:{
              'lineup-paid-date': LineUp.type === 0,
              'lineup-free-date': LineUp.type === 1,
            }},[_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(LineUp.start_date, LineUp.end_date))+" ")])]),_c('div',{staticClass:"expert-kakao-kakao-talk-btn"},[_c('button',{on:{"click":function($event){return _vm.openKakaoTalk(LineUp.kakao_talk)}}},[_vm._v("무료 카톡방")])]),_c('div',{staticClass:"line-up-btn",class:{
              'lineup-paid-broadcast': LineUp.type === 0,
              'lineup-free-broadcast': LineUp.type === 1,
            },on:{"click":function($event){return _vm.joinBroadcast(LineUp, LineUp.expert_id)}}},[_c('span',[_vm._v(" "+_vm._s(LineUp.type === 0 ? "유료 방송보기" : "무료 방송보기")+" ")])])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-up__header"},[_c('div',{staticClass:"line-up__header__title"},[_c('span',[_vm._v("전문가 라인업")])])])
}]

export { render, staticRenderFns }