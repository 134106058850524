<template>
  <div class="line-up" :class="{ mobile: isMobile }">
    <div class="line-up__wrapper">
      <div class="line-up__header">
        <div class="line-up__header__title">
          <span>전문가 라인업</span>
        </div>
      </div>
      <div class="line-up__content">
        <div class="line-up-card" v-for="(LineUp, index) in processedExpertData" :key="index">
          <div class="line-up-row1">
            <div class="line-up-profile">
              <div
                class="line-up-profile-image-container"
                @click="navigateToExpert(LineUp.expert_id)"
              >
                <div class="line-up-triangle-background"></div>
                <div :class="['line-up-profile-image', { 'image-error': LineUp.imageError }]">
                  <img
                    :src="LineUp.profile_img3"
                    @error="handleImageError(LineUp.expert_id)"
                    alt="Profile Image"
                  />
                </div>
                <div v-if="isBroadcastLive(LineUp)" class="live-badge-custom">
                  <div class="live-custom">
                    <v-icon>mdi-circle</v-icon>
                    <span>LIVE</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="line-up-row2">
            <div class="line-up-expert-des-container">
              <div class="line-up-expert-name">
                <span>{{ LineUp.expert_nickname }}</span>
              </div>
              <div class="line-up-expert-home-btn" @click="navigateToExpert(LineUp.expert_id)">
                <img class="img" :alt="`expert_home_svg`" :src="homeSvg" />
              </div>
              <!-- <div class="line-up-expert-kakao-btn" @click="openKakaoTalk(LineUp.kakao_talk)">
                <img class="img" :alt="`expert_kakao_svg`" :src="kakaoSvg" />
              </div> -->
            </div>
            <div class="line-up-title">
              <p>{{ LineUp.title }}</p>
            </div>
            <div
              class="line-up-date"
              :class="{
                'lineup-paid-date': LineUp.type === 0,
                'lineup-free-date': LineUp.type === 1,
              }"
            >
              <span>
                {{ formatDate(LineUp.start_date, LineUp.end_date) }}
              </span>
            </div>
            <div class="expert-kakao-kakao-talk-btn">
              <button @click="openKakaoTalk(LineUp.kakao_talk)">무료 카톡방</button>
            </div>
            <div
              class="line-up-btn"
              :class="{
                'lineup-paid-broadcast': LineUp.type === 0,
                'lineup-free-broadcast': LineUp.type === 1,
              }"
              @click="joinBroadcast(LineUp, LineUp.expert_id)"
            >
              <span>
                {{ LineUp.type === 0 ? "유료 방송보기" : "무료 방송보기" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";

export default {
  name: "LineUp",
  props: {
    expertData: {
      type: Array,
      default: () => [],
      required: true,
      validator: function (value) {
        if (!Array.isArray(value)) {
          console.error("expertData must be an array");
          return false;
        }
        return true;
      },
    },
  },
  data: () => ({
    homeSvg: require("@/assets/img/expert_home.svg"),
    kakaoSvg: require("@/assets/img/expert_kakao.svg"),
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
    processedExpertData() {
      return this.expertData
        .map((expert) => {
          const now = dayjs();
          const random = Math.floor(Math.random() * 31);
          const start = now.subtract(random, "day").toDate();
          const end = dayjs(start)
            .add(Math.floor(Math.random() * 3) + 1, "hour")
            .toDate();
          return {
            ...expert,
            profile_img3:
              expert.profile_img3 === "" || expert.profile_img3 === null
                ? require("@/assets/img/futureon-logo-white-1.png")
                : expert.profile_img3,
            title: expert.title || "방송 제목이 들어옵니다.",
            start_date: expert.start_date || start,
            end_date: expert.end_date || end,
            type: expert.type === 0 || expert.type === 1 ? expert.type : 0,
            imageError: !expert.profile_img3,
          };
        })
        .filter((expert) => expert.leaved === 0 && expert.lineup_order !== null)
        .sort((a, b) => a.lineup_order - b.lineup_order)
        .slice(0, 15);
    },
  },
  methods: {
    ...mapActions(["validateToken"]),
    formatDate(start, end) {
      const startTime = dayjs(start);
      const endTime = dayjs(end);
      return `${startTime.format("MM월 DD일 HH:mm")}~${endTime.format("HH:mm")}`;
    },
    isBroadcastLive(lineup) {
      const now = dayjs();
      const start = dayjs(lineup.start_date);
      const end = dayjs(lineup.end_date);
      return now.isAfter(start) && now.isBefore(end);
    },
    navigateToExpert(expert_id) {
      if (expert_id) {
        this.$router.push({ name: "LineupLayout", params: { expert_id: expert_id } });
      }
    },
    openKakaoTalk(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    async joinBroadcast(data, expert_id) {
      try {
        let userId = null;
        let reqData = {};

        if (data.type === 0) {
          const userToken = await this.validateToken();

          if (
            !userToken ||
            !userToken.data ||
            !userToken.data.user ||
            userToken.data.user.length === 0
          ) {
            throw new Error("Invalid token data");
          }
          userId = userToken.data.user[0].user_id;
          reqData = {
            user_id: userId,
            expert_id: expert_id,
            base_url: `${window.location.origin}/${
              this.$router.resolve({
                name: "LineupLayout",
              }).href
            }`,
            register_url: `${window.location.origin}/${
              this.$router.resolve({
                name: "RegisterView",
              }).href
            }`,
          };
        } else if (data.type === 1) {
          const userToken = await this.validateToken().catch(() => {
            reqData = { expert_id: expert_id };
          });

          if (
            userToken &&
            userToken.data &&
            userToken.data.user &&
            userToken.data.user.length > 0
          ) {
            userId = userToken.data.user[0].user_id;
            reqData = {
              user_id: userId,
              expert_id: expert_id,
              base_url: `${window.location.origin}/${
                this.$router.resolve({
                  name: "LineupLayout",
                }).href
              }`,
              register_url: `${window.location.origin}/${
                this.$router.resolve({
                  name: "RegisterView",
                }).href
              }`,
            };
          }
        }

        const joinUrl = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/content/join_broadcast`,
          reqData
        );

        if (joinUrl.data.url && joinUrl.status === 200) {
          window.open(
            joinUrl.data.url,
            "_blank",
            "toolbar=no,scrollbars=yes,resizable=yes,top=100,left=100,width=1024,height=768"
          );
        } else {
          alert("방송중이 아닙니다.");
        }
      } catch (error) {
        console.error("Error in joinBroadcast:", error.message);
        if (error.response && error.response.status === 401) {
          const confirmation = confirm("로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?");
          if (confirmation) {
            this.$router.push("/login");
          }
        } else if (error.response && error.response.data.message === "Invalid broadcast data") {
          alert("방송중이 아닙니다.");
        } else if (error.response && error.response.data.message === "deny") {
          alert("전문가 상품 구매 후 이용 가능한 방송입니다.");
        } else {
          alert("방송중이 아닙니다.");
        }
      }
    },
    handleImageError(expertId) {
      const index = this.processedExpertData.findIndex((expert) => expert.expert_id === expertId);
      if (index !== -1) {
        this.$set(this.processedExpertData, index, {
          ...this.processedExpertData[index],
          imageError: true,
        });
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.line-up {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  /* height: 100vh; */
  /* max-height: 65rem; */
  background: #fff;
}
.line-up.mobile {
  padding: 1rem 0;
  height: auto;
  max-height: 100%;
}

.line-up__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  /* max-height: 65rem; */
}
.line-up.mobile .line-up__wrapper {
  height: auto;
  max-height: 100%;
}

.line-up__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 4rem; */
  max-height: 4rem;
  background: #fff;
}
.line-up.mobile .line-up__header {
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.line-up__header__title {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 700;
  color: #212121;
  white-space: nowrap;
}

.line-up__content {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
  position: relative;
  width: 100%;
  max-width: 75rem;
  /* height: 100%; */
  /* max-height: 65rem; */
  gap: 1.875rem;
  margin-bottom: 1rem;
}
.line-up.mobile .line-up__content {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 100%;
  margin-bottom: 1rem;
}

.line-up-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 17.5rem;
  height: 25.625rem;
  border-radius: 0.625rem;
  border: 0.065rem solid #e0e0e0;
}
.line-up.mobile .line-up-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 30.565rem;
  height: 224px;
  max-height: 15.4385rem;
}

.line-up-row1 {
  width: 100%;
  height: 100%;
  max-height: 11.565rem;
}
.line-up.mobile .line-up-row1 {
  width: 100%;
  max-width: 18.25rem;
  height: 100%;
  max-height: 15.4385rem;
  cursor: pointer;
  border-radius: 0.625rem 0 0 0.625rem;
}

.line-up-row2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 0 0;
}
.line-up.mobile .line-up-row2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 18.25rem;
  height: 100%;
  max-height: 15.4385rem;
  padding: 0.5rem 0 0 0;
  background: #fff;
  border-radius: 0 0.625rem 0.625rem 0;
}

.line-up-profile-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 11.565rem;
  overflow: hidden;
  border-radius: 0.625rem 0.625rem 0 0;
  background: #bbc2d4;
}
.line-up.mobile .line-up-profile-image-container {
  width: 100%;
  max-width: 15.25rem;
  height: 100%;
  max-height: 15.44rem;
  border-radius: 0.625rem 0 0 0.625rem;
}

.line-up-triangle-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.line-up-triangle-background::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #b7bed0;
  clip-path: polygon(0 100%, 0 0, 100% 100%);
  z-index: 0;
}

.line-up-row1 .line-up-profile {
  width: 100%;
  height: 100%;
}

.line-up-profile-image {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.line-up-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
}
.line-up.mobile .line-up-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

.live-badge-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  right: 3%;
  width: 4rem;
  height: 1.5rem;
  opacity: 0.9;
  border-radius: 3.125rem;
  background: #d32f2f;
}

.live-custom {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.live-badge-custom i {
  color: #fff;
  font-size: 0.5rem;
}

.live-badge-custom span {
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  color: #f5f5f5;
}

.line-up-expert-des-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 2.5rem;
  gap: 0.4rem;
  padding: 0 0.5rem 0 0.5rem;
}

.line-up-expert-name {
  display: flex;
  flex-grow: 1;
  position: relative;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: left;
  color: #212121;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-up-expert-home-btn,
.line-up-expert-kakao-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.line-up-title {
  position: relative;
  padding: 0 0.5rem 0 0.5rem;
  height: 100%;
  max-height: 3.97rem;
  display: -webkit-box;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.line-up.mobile .line-up-title {
  max-height: 5.25rem;
}

.line-up-title p {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.85rem;
  text-align: left;
  color: #000000;
  margin: 0;
}

.line-up-date {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 2rem;
}

.line-up-date span {
  font-size: 14px;
  font-weight: bold;
  line-height: 16.8px;
  text-align: left;
}

.line-up-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 3rem;
  border-radius: 0 0 0.625rem 0.625rem;
  cursor: pointer;
}
.line-up.mobile .line-up-btn {
  border-radius: 0 0 0.625rem 0;
}

.line-up-btn span {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.75rem;
}

.lineup-paid-date {
  background: #ffebcc;
  color: #e85a1c;
}

.lineup-free-date {
  background: #e3f2fd;
  color: #0d47a1;
}

.lineup-paid-broadcast {
  background: linear-gradient(90deg, #e85a1c -0.17%, #ff9900 100%);
  color: #e3f2fd;
}

.lineup-free-broadcast {
  background: #263238;
  color: #e3f2fd;
}

@keyframes blink {
  0%,
  50% {
    background-color: #0e42aa;
  }
  50.1%,
  100% {
    background-color: #4589ff;
  }
}
.lineup-free-broadcast {
  color: #e3f2fd;
  animation: blink 1.5s linear infinite;
}

.lineup-record-broadcast {
  background: #00acc1;
  color: #e3f2fd;
}

.expert-kakao-kakao-talk-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
  padding: 10px;
  background: #fae300;
  color: #371c1d;
  font-size: 1rem;
  font-weight: bold;
  line-height: 28px;
}
.line-up.mobile .expert-kakao-kakao-talk-btn {
  padding: 0;
}

.line-up-profile-image.image-error {
  background-image: url("@/assets/img/futureon-logo-white-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.line-up-profile-image.image-error img {
  display: none;
}
</style>
