<template>
  <section class="swiper-event-container" :class="{ mobile: isMobile }" v-if="!isMobile">
    <div class="swiper-container" ref="swiperContainer">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
          <div class="banner">
            <img
              :src="slide.imageUrl"
              @error="handleImageError(index)"
              alt="Banner Image"
              class="banner-image"
              @click="openBannerLink(slide.link)"
            />
            <!-- <div class="d-day">
              <div class="text-wrapper-2">{{ slide.dDayText }}</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Swiper from "swiper";
import "swiper/css/swiper.css";

// 스와이퍼 + vue2 + vue-awesome-swiper loop + pagination 사용시 bug로 인해 swiper 직접 커스텀
// vue3 >>> swiper/vue 사용

export default {
  name: "EventBanner",
  data: () => ({
    swiper: null,
    swiperOptions: {
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    },
    defaultSlide: {
      imageUrl: require("@/assets/img/event_banner.png"),
      // dDayText: "D-0",
    },
    slides: [],
  }),
  mounted() {
    this.fetchEventBanner();
  },
  watch: {
    slides() {
      this.initializeSwiper();
    },
  },
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
  methods: {
    async fetchEventBanner() {
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/banner/banner_list`,
          {
            params: {
              status: 1,
              area: 0,
            },
          }
        );

        const slides = response.data.map((slide) => ({
          imageUrl: this.isValidImageUrl(slide.banner_img)
            ? slide.banner_img
            : this.defaultSlide.imageUrl,
          // dDayText: this.calculateDDay(slide.end_date),
          link: slide.banner_link,
        }));

        if (slides.length > 0 && slides.length <= 5) {
          this.slides = slides;
        } else if (slides.length === 0) {
          this.slides = [];
        } else {
          this.slides = slides;
        }
      } catch (error) {
        if (error.response.status === 404) {
          this.slides = new Array(5).fill(this.defaultSlide);
        } else {
          console.error("There was an error fetching the event data:", error);
        }
      }
    },
    isValidImageUrl(url) {
      return typeof url === "string" && url.trim() !== "";
    },
    handleImageError(index) {
      this.$set(this.slides, index, {
        ...this.slides[index],
        imageUrl: this.defaultSlide.imageUrl,
      });
    },
    // calculateDDay(endDate) {
    //   const today = this.$dayjs();
    //   const end = this.$dayjs(endDate);
    //   const diff = end.diff(today, "day");
    //   if (diff < 0) {
    //     return "D-0";
    //   }

    //   return `D-${diff}`;
    // },
    openBannerLink(link) {
      window.open(link, "_blank");
    },
    initializeSwiper() {
      this.$nextTick(() => {
        if (this.swiper) {
          this.swiper.destroy(true, true);
        }
        this.swiper = new Swiper(this.$refs.swiperContainer, this.swiperOptions);
      });
    },
  },
};
</script>

<style>
div.swiper-event-container ul,
div.swiper-event-container ol {
  padding-left: 0;
  margin-left: 0;
}

.swiper-event-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  height: 100vh;
  max-height: 13.75rem;
}
.swiper-event-container.mobile {
  max-height: 3rem;
  margin-top: 10px;
}
.swiper-event-container.mobile .swiper-wrapper {
  max-height: 3rem;
}

.swiper-event-container .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.banner {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 8.75rem;
  border-radius: 0.625rem;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.d-day {
  display: flex;
  position: absolute;
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 3.75rem;
  height: 100%;
  max-height: 2.5rem;
  top: 6.5%;
  right: 0.75%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 1.25rem;
}
.swiper-event-container.mobile .d-day {
  max-width: 2.45rem;
  max-height: 1.625rem;
}

.text-wrapper-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
}
.swiper-event-container.mobile .text-wrapper-2 {
  font-size: 0.65rem;
}

.swiper-pagination {
  bottom: 3% !important;
  text-align: center;
}

.swiper-pagination-bullet {
  display: inline-flex;
  padding: 0;
  margin: 0 0.1rem !important;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  opacity: 0.5;
  transition: opacity 0.2s;
}
.swiper-pagination-bullet:hover {
  background-color: #fff;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 1;
}

.swiper-event-container.mobile .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
}
</style>
