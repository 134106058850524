<template>
  <div class="quick-menu">
    <div class="quick-menu-container">
      <div class="quick-menu-wrapper">
        <div class="quick-menu-icon-wrapper" @click="handleMyPageClick">
          <svg
            class="quick-mypage-icon"
            width="42"
            height="33"
            viewBox="0 0 42 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <g clip-path="url(#clip0_40_741)">
              <path
                d="M24.9082 31.862H2.52659C1.75537 31.862 1.13184 31.0662 1.13184 30.0864V25.5579C1.13184 25.0227 1.32171 24.5184 1.64754 24.1815C1.83742 23.9831 6.4061 19.3447 13.7198 19.3447C16.3241 19.3447 18.5815 19.9326 20.4169 20.6997"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M19.4987 16.7626C22.5336 13.6767 22.5336 8.6734 19.4987 5.58747C16.4637 2.50153 11.543 2.50153 8.50801 5.58747C5.47303 8.6734 5.47303 13.6767 8.50801 16.7626C11.543 19.8486 16.4637 19.8486 19.4987 16.7626Z"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M36.1982 20L38.0845 24.12L39.9707 20H41.9997L39.0276 25.7456V29.0341H37.1414V25.7456L34.1631 20H36.1982Z"
                fill="#212121"
              ></path>
              <path
                d="M24.8501 20H26.4261L28.7466 26.6329L31.0672 20H32.6432L29.3795 29.0341H28.1138L24.8501 20ZM24 20H25.5698L25.8552 26.4654V29.0341H24V20ZM31.9235 20H33.4995V29.0341H31.6381V26.4654L31.9235 20Z"
                fill="#212121"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_40_741">
                <rect width="42" height="33" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
          <p class="quick-mypage-text">마이페이지</p>
        </div>

        <div class="quick-menu-icon-wrapper" @click="handleNoticeClick">
          <svg
            class="quick-notice-icon"
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <g clip-path="url(#clip0_40_805)">
              <path
                d="M29.6553 18.3829C27.39 18.3829 25.5537 16.5328 25.5537 14.2505C25.5537 11.9683 27.39 10.1182 29.6553 10.1182C31.9205 10.1182 33.7568 11.9683 33.7568 14.2505C33.7568 16.5328 31.9205 18.3829 29.6553 18.3829Z"
                fill="white"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M12.379 19.2094L29.6553 25.9715V2.51709L12.379 9.27916V19.2094Z"
                fill="white"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M12.3789 9.27905H6.17685C3.4549 9.27905 1.24254 11.508 1.24254 14.2504C1.24254 16.9928 3.4549 19.2218 6.17685 19.2218H12.3789V9.29158V9.27905Z"
                fill="white"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M29.6553 1.2522V27.2361"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M10.3781 33.7477C12.1679 33.7477 13.6221 32.2826 13.6221 30.4794L12.3792 19.2092H5.89125L7.13414 30.4794C7.13414 32.2826 8.58834 33.7477 10.3781 33.7477Z"
                fill="white"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_40_805">
                <rect width="35" height="35" fill="white" transform="matrix(-1 0 0 1 35 0)"></rect>
              </clipPath>
            </defs>
          </svg>
          <p class="quick-notice-text">공지사항</p>
        </div>

        <div class="quick-menu-icon-wrapper" @click="handleKakaoClick">
          <svg
            class="quick-kakao-icon"
            width="46"
            height="39"
            viewBox="0 0 46 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path
              d="M11.6575 31.3322L11.8928 30.508L11.1143 30.1494C4.91987 27.2959 1 22.2994 1 16.7813C1 12.5348 3.30998 8.61232 7.20156 5.71652C11.0932 2.82066 16.5163 1 22.55 1C28.5835 1 34.0067 2.8216 37.8984 5.71797C41.7901 8.61436 44.1 12.5368 44.1 16.7813C44.1 21.0258 41.7902 24.9472 37.8985 27.8426C34.0068 30.738 28.5837 32.5586 22.55 32.5586C21.1661 32.5586 19.8154 32.4617 18.5018 32.2796L18.1363 32.2289L17.8255 32.4277C17.1633 32.8514 16.2714 33.4222 15.3105 34.0372C13.3403 35.2982 11.0801 36.7447 9.91638 37.4884C10.3583 35.899 11.2659 32.7036 11.6575 31.3322Z"
              fill="white"
              stroke="#212121"
              stroke-width="2"
            ></path>
          </svg>
          <div class="quick-menu-icon-talk-text">
            <svg
              width="34"
              height="11"
              viewBox="0 0 34 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                d="M1.72586 2.70899H4.03636C4.03636 2.70899 4.04908 8.71671 4.03636 10.1173C4.03636 11.1407 6.44012 11.1527 6.43588 10.1334L6.44436 2.78926C6.44436 2.78926 7.22018 2.79327 8.74214 2.79327C10.1793 2.79327 10.1793 0.654253 8.74214 0.654253C7.12267 0.654253 1.71314 0.610107 1.71314 0.610107C0.356521 0.610107 0.36076 2.70899 1.71314 2.70899"
                fill="#212121"
              ></path>
              <mask
                id="mask0_40_751"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="10"
                height="11"
              >
                <path
                  d="M1.72586 2.70899H4.03636C4.03636 2.70899 4.04908 8.71671 4.03636 10.1173C4.03636 11.1407 6.44012 11.1527 6.43588 10.1334L6.44436 2.78926C6.44436 2.78926 7.22018 2.79327 8.74214 2.79327C10.1793 2.79327 10.1793 0.654253 8.74214 0.654253C7.12267 0.654253 1.71314 0.610107 1.71314 0.610107C0.356521 0.610107 0.36076 2.70899 1.71314 2.70899"
                  fill="white"
                ></path>
              </mask>
              <g mask="url(#mask0_40_751)">
                <path
                  d="M10.1877 0.610107H0.369141V11.1527H10.1877V0.610107Z"
                  fill="#212121"
                ></path>
              </g>
              <path
                d="M12.786 3.09832L14.0706 6.91083H11.3913L12.786 3.09832ZM11.3446 1.31246C10.7765 2.49634 8.72041 8.09471 8.0421 9.57958C7.55033 10.6511 9.67429 11.526 10.1661 10.4545L10.6282 8.92544H14.8549C14.8549 8.92544 14.7107 8.98965 15.2703 10.3902C15.724 11.526 17.92 10.7394 17.4664 9.60366C16.8177 7.98234 14.5708 2.13917 14.1596 1.31246C13.9731 0.931205 13.2948 0.654297 12.6377 0.654297C12.095 0.654297 11.5651 0.842916 11.3404 1.31246"
                fill="#212121"
              ></path>
              <mask
                id="mask1_40_751"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="7"
                y="0"
                width="11"
                height="11"
              >
                <path
                  d="M12.786 3.09832L14.0706 6.91083H11.3913L12.786 3.09832ZM11.3446 1.31246C10.7765 2.49634 8.72041 8.09471 8.0421 9.57958C7.55033 10.6511 9.67429 11.526 10.1661 10.4545L10.6282 8.92544H14.8549C14.8549 8.92544 14.7107 8.98965 15.2703 10.3902C15.724 11.526 17.92 10.7394 17.4664 9.60366C16.8177 7.98234 14.5708 2.13917 14.1596 1.31246C13.9731 0.931205 13.2948 0.654297 12.6377 0.654297C12.095 0.654297 11.5651 0.842916 11.3404 1.31246"
                  fill="white"
                ></path>
              </mask>
              <g mask="url(#mask1_40_751)">
                <path d="M17.9241 0.654297H7.5459V11.53H17.9241V0.654297Z" fill="#212121"></path>
              </g>
              <path
                d="M18.2813 1.6616C18.2813 3.56384 18.294 9.69596 18.294 9.69596C18.294 9.69596 18.1075 10.916 19.2139 10.916C20.3204 10.916 22.7666 10.9039 23.8519 10.9039C24.9372 10.9039 24.933 8.71675 23.8477 8.71675C22.7624 8.71675 20.7783 8.70471 20.7783 8.70471C20.7783 8.70471 20.7868 3.03411 20.7825 1.65358C20.7825 0.98739 20.1551 0.654297 19.5319 0.654297C18.9087 0.654297 18.277 0.991403 18.2813 1.65759"
                fill="#212121"
              ></path>
              <mask
                id="mask2_40_751"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="18"
                y="0"
                width="7"
                height="11"
              >
                <path
                  d="M18.2813 1.6616C18.2813 3.56384 18.294 9.69596 18.294 9.69596C18.294 9.69596 18.1075 10.916 19.2139 10.916C20.3204 10.916 22.7666 10.9039 23.8519 10.9039C24.9372 10.9039 24.933 8.71675 23.8477 8.71675C22.7624 8.71675 20.7783 8.70471 20.7783 8.70471C20.7783 8.70471 20.7868 3.03411 20.7825 1.65358C20.7825 0.98739 20.1551 0.654297 19.5319 0.654297C18.9087 0.654297 18.277 0.991403 18.2813 1.65759"
                  fill="white"
                ></path>
              </mask>
              <g mask="url(#mask2_40_751)">
                <path d="M24.9372 0.654297H18.1074V10.912H24.9372V0.654297Z" fill="#212121"></path>
              </g>
              <path
                d="M25.225 1.3565C25.2504 2.19123 25.1953 9.73599 25.1953 10.2938C25.1953 11.1767 27.5694 11.1727 27.5694 10.2938C27.5694 9.27046 27.5694 7.32408 27.5694 7.32408L28.2647 6.79434L31.3128 10.7272C31.9275 11.4978 33.865 10.1052 33.2503 9.33869L30.0156 5.30545C30.0156 5.30545 32.2116 3.05808 33.0764 2.2434C33.5809 1.76985 32.0166 0.276953 31.5121 0.75452C31.0245 1.21202 27.5821 4.47473 27.5821 4.47473C27.5821 4.47473 27.6118 2.42801 27.5821 1.2963C27.5694 0.866888 27.014 0.658203 26.4417 0.658203C25.8312 0.658203 25.208 0.894981 25.2207 1.3565"
                fill="#212121"
              ></path>
              <mask
                id="mask3_40_751"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="25"
                y="0"
                width="9"
                height="11"
              >
                <path
                  d="M25.225 1.3565C25.2504 2.19123 25.1953 9.73599 25.1953 10.2938C25.1953 11.1767 27.5694 11.1727 27.5694 10.2938C27.5694 9.27046 27.5694 7.32408 27.5694 7.32408L28.2647 6.79434L31.3128 10.7272C31.9275 11.4978 33.865 10.1052 33.2503 9.33869L30.0156 5.30545C30.0156 5.30545 32.2116 3.05808 33.0764 2.2434C33.5809 1.76985 32.0166 0.276953 31.5121 0.75452C31.0245 1.21202 27.5821 4.47473 27.5821 4.47473C27.5821 4.47473 27.6118 2.42801 27.5821 1.2963C27.5694 0.866888 27.014 0.658203 26.4417 0.658203C25.8312 0.658203 25.208 0.894981 25.2207 1.3565"
                  fill="white"
                ></path>
              </mask>
              <g mask="url(#mask3_40_751)">
                <path d="M33.865 0.28125H25.1953V11.5021H33.865V0.28125Z" fill="#212121"></path>
              </g>
            </svg>
          </div>
          <p class="quick-kakao-text">카카오 상담</p>
        </div>

        <div class="quick-menu-icon-wrapper" @click="handleExpertClick">
          <svg
            class="quick-expert-icon"
            width="31"
            height="39"
            viewBox="0 0 31 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path
              d="M15.5003 17.2537C11.8221 17.2537 8.83984 14.3405 8.83984 10.7475V8.9789C8.83984 5.38584 11.8221 2.47266 15.5003 2.47266C19.1786 2.47266 22.1608 5.38584 22.1608 8.9789V10.7475C22.1608 14.3405 19.1786 17.2537 15.5003 17.2537Z"
              fill="white"
              stroke="#212121"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M15.491 17.2539H15.5131C22.6757 17.2539 28.4904 22.934 28.4904 29.9308V31.4196C28.4904 34.2424 26.1469 36.5317 23.2572 36.5317H7.7469C4.85717 36.5317 2.51367 34.2424 2.51367 31.4196V29.9308C2.51367 22.934 8.32837 17.2539 15.491 17.2539Z"
              fill="white"
              stroke="#212121"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M8.04227 26.1956L15.5001 30.5245L10.6325 18.179C8.90126 18.8631 7.35508 19.9045 6.09082 21.204L9.67655 23.5148L8.04227 26.1913V26.1956Z"
              fill="white"
              stroke="#212121"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M22.9578 26.1957L21.3235 23.5192L24.9092 21.2084C23.6406 19.9046 22.0988 18.8675 20.3676 18.1833L15.5 30.5289L22.9578 26.2V26.1957Z"
              fill="white"
              stroke="#212121"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          <p class="quick-expert-text">전문가 모집</p>
        </div>

        <div class="quick-menu-icon-wrapper" @click="handleRemoteClick">
          <svg
            class="quick-remote-icon"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <g clip-path="url(#clip0_40_787)">
              <path
                d="M30.907 12.2042V24.4561C30.907 25.0294 30.4344 25.4939 29.8513 25.4939H2.40429C1.82116 25.4939 1.34863 25.0294 1.34863 24.4561V6.81717C1.34863 6.24881 1.82116 5.7793 2.40429 5.7793H18.772"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M9.0957 30.6636H23.161"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1.34863 22.0789H30.907"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M12.6943 25.499V30.6637"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M19.5615 25.583V30.6636"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M27.6999 8.1122C27.6999 9.54051 26.5236 10.7019 25.0658 10.7019C23.6079 10.7019 22.4316 9.54546 22.4316 8.1122C22.4316 6.67895 23.6079 5.52246 25.0658 5.52246C26.5236 5.52246 27.6999 6.67895 27.6999 8.1122Z"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M30.148 8.11226C30.148 7.71193 30.0977 7.32644 30.0072 6.95577L31.656 5.77457L30.4244 3.67411L28.5595 4.48958C27.9864 3.95582 27.2927 3.5555 26.5185 3.32815L26.3024 1.33643H23.8341L23.618 3.32815C22.8438 3.5555 22.1501 3.95582 21.5771 4.48958L19.7121 3.67411L18.4805 5.77457L20.1293 6.95577C20.0388 7.32644 19.9885 7.71688 19.9885 8.11226C19.9885 8.50764 20.0388 8.89807 20.1293 9.26874L18.4805 10.4499L19.7121 12.5504L21.5771 11.7349C22.1501 12.2687 22.8438 12.669 23.618 12.8964L23.8341 14.8881H26.3024L26.5185 12.8964C27.2927 12.669 27.9864 12.2687 28.5595 11.7349L30.4244 12.5504L31.656 10.4499L30.0072 9.26874C30.0977 8.89807 30.148 8.50764 30.148 8.11226Z"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_40_787">
                <rect width="33" height="32" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
          <p class="quick-remote-text">원격지원</p>
        </div>

        <div class="quick-menu-icon-wrapper" @click="handleCustomerClick">
          <svg
            class="quick-customer-icon"
            width="41"
            height="36"
            viewBox="0 0 41 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_40_797)">
              <path
                d="M6.62988 24.2574V14.0315C6.62988 10.8936 7.81507 7.79266 10.1854 5.43001C12.5558 3.06736 15.6669 1.84912 18.741 1.84912H22.1114C25.2225 1.84912 28.2966 3.03045 30.6669 5.43001C33.0373 7.79266 34.2225 10.9306 34.2225 14.0315V24.2574"
                stroke="#212121"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M34.5181 11.4473C34.5181 11.4473 34.3699 11.4473 34.2588 11.4473V23.7774C34.2588 23.7774 34.4069 23.7774 34.5181 23.7774C36.4069 23.7774 38.0366 22.9283 39.1847 21.5993V13.5885C38.0736 12.2595 36.4069 11.4104 34.5181 11.4104V11.4473Z"
                stroke="#212121"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              ></path>
              <path
                d="M6.48112 11.4473C6.48112 11.4473 6.62927 11.4473 6.74038 11.4473V23.7774C6.74038 23.7774 6.59223 23.7774 6.48112 23.7774C4.59223 23.7774 2.9626 22.9283 1.81445 21.5993V13.5885C2.92556 12.2595 4.59223 11.4104 6.48112 11.4104V11.4473Z"
                stroke="#212121"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linejoin="round"
              ></path>
              <path
                d="M25.7776 32.0099C25.7776 30.8285 24.8146 29.8318 23.5924 29.8318C22.3702 29.8318 21.4072 30.7916 21.4072 32.0099C21.4072 33.2281 22.3702 34.1879 23.5924 34.1879C24.8146 34.1879 25.7776 33.2281 25.7776 32.0099Z"
                stroke="#212121"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M25.7773 32.0097H30.5922C32.1477 32.0097 33.7403 31.4191 34.9255 30.2377C36.1107 29.0564 36.7033 27.5059 36.7033 25.9185V23.4082"
                stroke="#212121"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_40_797">
                <rect width="41" height="36" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
          <p class="quick-customer-text">Q&A</p>
        </div>

        <svg
          class="horizontal-line1"
          width="110"
          height="1"
          viewBox="0 0 110 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
        >
          <line y1="0.5" x2="110" y2="0.5" stroke="#E0E0E0"></line>
        </svg>
        <svg
          class="horizontal-line2"
          width="110"
          height="1"
          viewBox="0 0 110 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
        >
          <line y1="0.5" x2="110" y2="0.5" stroke="#E0E0E0"></line>
        </svg>
        <svg
          class="horizontal-line3"
          width="110"
          height="1"
          viewBox="0 0 110 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
        >
          <line y1="0.5" x2="110" y2="0.5" stroke="#E0E0E0"></line>
        </svg>
        <svg
          class="horizontal-line4"
          width="110"
          height="1"
          viewBox="0 0 110 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
        >
          <line y1="0.5" x2="110" y2="0.5" stroke="#E0E0E0"></line>
        </svg>
        <svg
          class="horizontal-line5"
          width="110"
          height="1"
          viewBox="0 0 110 1"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
        >
          <line y1="0.5" x2="110" y2="0.5" stroke="#E0E0E0"></line>
        </svg>
      </div>
      <div class="quick-menu-top-wrapper">
        <svg
          class="quick-top-icon"
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
        >
          <circle cx="25.5" cy="25.5" r="25.5" fill="#4589FF"></circle>
        </svg>
        <p class="quick-top-text">TOP</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "QuickMenu",
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleMyPageClick() {
      if (this.isLoggedIn) {
        this.$router.push("/mypage/myinfo");
      } else {
        alert("로그인이 필요한 기능입니다.");
      }
    },
    handleNoticeClick() {
      this.$router.push("/notice/list");
    },
    handleKakaoClick() {
      window.open("https://open.kakao.com/o/sv7GvILg", "_blank");
    },
    handleExpertClick() {
      window.open(
        "https://home.futureon.co.kr/#/notice/view?currentIndex=0&page=1&pageCount=1&limit=5",
        "_blank"
      );
    },
    handleRemoteClick() {
      window.open("http://ezh.kr/futureson/", "_blank");
    },
    handleCustomerClick() {
      this.$router.push("/notice/qna");
    },
  },
  mounted() {
    const topButton = document.querySelector(".quick-menu-top-wrapper");
    topButton.addEventListener("click", this.scrollToTop);
  },
  beforeDestroy() {
    const topButton = document.querySelector(".quick-menu-top-wrapper");
    topButton.removeEventListener("click", this.scrollToTop);
  },
};
</script>
<style>
.quick-menu {
  position: fixed;
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.quick-menu-container {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  width: 110px;
  height: 660px;
}

.quick-menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 110px;
  height: 599px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #e0e0e0;
}

.quick-menu-wrapper p {
  margin: 0;
}

.quick-menu-icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 100px;
  cursor: pointer;
  gap: 10px;
}

.quick-menu-top-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 61px;
  cursor: pointer;
}

.quick-mypage-icon {
  width: 42px;
  height: 33px;
}

.quick-mypage-text {
  font-size: 16px;
  text-align: center;
  color: #212121;
}

.quick-notice-icon {
  width: 35px;
  height: 35px;
}

.quick-notice-text {
  font-size: 16px;
  text-align: center;
  color: #212121;
}

.quick-kakao-icon {
  width: 46px;
  height: 39px;
}

.quick-menu-icon-talk-text {
  width: 32px;
  height: 10px;
  position: absolute;
  top: 218px;
  left: 38px;
}

.quick-kakao-text {
  font-size: 16px;
  text-align: center;
  color: #212121;
}

.quick-expert-icon {
  width: 31px;
  height: 39px;
}

.quick-expert-text {
  font-size: 16px;
  text-align: center;
  color: #212121;
}

.quick-remote-icon {
  width: 33px;
  height: 32px;
}

.quick-remote-text {
  font-size: 16px;
  text-align: center;
  color: #212121;
}

.quick-customer-icon {
  width: 41px;
  height: 36px;
}

.quick-customer-text {
  width: 64px;
  font-size: 16px;
  text-align: center;
  color: #212121;
}

.quick-top-icon {
}

.quick-top-text {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 619px;
}

.horizontal-line1 {
  position: absolute;
  left: -1px;
  top: 98px;
}

.horizontal-line2 {
  position: absolute;
  left: -1px;
  top: 198px;
}

.horizontal-line3 {
  position: absolute;
  left: -1px;
  top: 298px;
}

.horizontal-line4 {
  position: absolute;
  left: -1px;
  top: 398px;
}

.horizontal-line5 {
  position: absolute;
  left: -1px;
  top: 498px;
}
</style>
